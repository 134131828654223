
//import { useState,useEffect,useRef,ChangeEvent, FormEvent } from 'react';
import React, { useEffect,useState,useRef } from 'react';
import emailjs from '@emailjs/browser';

//import ReactDOM from 'react-dom/client';
import './App.scss';
import Card from 'react-bootstrap/Card'
import { Button } from 'react-bootstrap';
import axios from "axios";
import ReactDOM from "react-dom"
//import './EmailForm.css';
//var request = require('request');
function App() {
	const [fname, setFname] = useState("");	
	const [lname, setLname] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [dateTime, setDateTime] = useState("");
	const [pickup, setPickup] = useState("");
	const [drop, setDrop] = useState("");
	const [error, setError] = useState("");
	const [loader, setLoader] = useState(true);
	const formdata = useRef(null);
	useEffect(() => {
		// Update the document title using the browser API		
		setLoader(false);
	  });
	  const sendEmail = (FormEvent:any) => {
		console.log("In form")
        if(fname==null || fname==''){
            setError("Please enter first name");
            return false;
        }
        if(lname==null || lname==''){
            setError("Please enter last name");
            return false;
        }
        if(mobileNo==null || mobileNo==''){
            setError("Please enter Mobile No");
            return false;
        }
        if(dateTime==null || dateTime==''){
            setError("Please enter journey date");
            return false;
        }
        if(pickup==null || pickup==''){
            setError("Please enter pickup location");
            return false;
        }
        if(drop==null || drop==''){
            setError("Please enter drop location");
            return false;
        }
        setError("");
		FormEvent.preventDefault(); // prevents the page from reloading when you hit “Send”
	 
		emailjs.sendForm('service_o5kkbzp', 'template_yj6ty69', FormEvent.target, 'dijPa43XKW_UXJjnh')
		  .then((result) => {
			  // show the user a success message
		  }, (error) => {
			  // show the user an error
		  });
          setFname('');
          setLname('');
          setMobileNo('');
          setDateTime('');
          setPickup('');
          setDrop('');
          setError('Thank you for your intereset, We will call you back shortly');
	  };
	//sendSMS();
  return (
    <div className="App">
        <div className='row top-header' style={{ backgroundColor: '#0e0d0d' }}>
            <div className="header-detail row" style={{ width: '100%', marginLeft: 30 }}>
                <div className='row' style={{ width: '100%',paddingTop:10,paddingBottom:10}} >
                    <div className='col-md-3 col-sm-12'>
                        <a href="https://api.whatsapp.com/send?phone=9309226943" ><i className="fa-brands fa-whatsapp top-icon" aria-hidden="true"> </i> What's App</a>
                    </div>
                    <div className='col-md-3 col-sm-12'> 
                        <a href="tel:+91 9309226943"><i className="fa-solid fa-phone-volume top-icon" aria-hidden="true"></i> +91 9309226943</a>
                    </div>
                    <div className='col-md-3 col-sm-12'>
                        <a href="mailto:ekviraaaicab@gmail.com" title="Email Us"> <i className="fa fa-envelope top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="Facebook"> <i className="fa-brands fa-facebook top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="YouTube"> <i className="fa-brands fa-youtube top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="Instagram"> <i className="fa-brands fa-instagram top-icon" aria-hidden="true"></i> </a>
                        <a href="#" title="LinkedIn"> <i className="fa-brands fa-linkedin top-icon" aria-hidden="true"></i> </a>
                    </div>
                </div>

            </div>
        </div>
      <header className="App-header">
	  <div className="container-xxl bg-white p-0">
        {
			loader==true?<div id="spinner" className=" spinner show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
				<div className="spinner-grow text-primary" style={{width: '3rem', height: '3rem'}} role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>:null
		}
		
        <div className="container-xxl position-relative p-0 bgcolor">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 fixed-top">
                <a href="" className="navbar-brand p-0" style={{display:'flex'}}>					
					<img src="images/ekviraaai_logo.png" alt="Logo"/> 
                    <h1 className="m-0" style={{marginLeft:'10px !important'}}>Ekviraai Cab</h1>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav mx-auto py-0">
                        <a href="#home" className="nav-item nav-link active">Home</a>
                        <a href="#services" className="nav-item nav-link">Service</a>
                        <a href="#feedback" className="nav-item nav-link">Feedback</a>
                        <a href="#cabs" className="nav-item nav-link">Cabs</a>
                        
                        <a href="#contactus" className="nav-item nav-link">Contact</a>
                    </div>
                    <a href="tel:+91 9309226943" className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">+91 9309226943</a>
					
                </div>
            </nav>

            <div className="container-xxl  hero-header" id="home">
                <div className="container px-lg-5">
                    <div className="row g-5 align-items-end">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h1 className="text-white mb-4 animated slideInDown">Ride in Style, Pay with a Smile</h1>
                            <p className="text-white pb-3 animated slideInDown">
								Your satisfaction is our top priority. Our friendly and professional staff is here to assist you every step of the way, from the moment you make a reservation to the time you return your vehicle.
							</p>
                        </div>
                        <div className="col-lg-6 text-center text-lg-start">
                            <img className="img-fluid animated zoomIn" style={{borderRadius:"2%"}} src="images/ekvira_top.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

		<div className="container-xxl py-5" id="contactus"> 
            <div className="container py-5 px-lg-5">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                    <p className="section-title text-secondary justify-content-center"><span></span>Book Now<span></span></p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="wow fadeInUp" data-wow-delay="0.3s">
                            <div style={{color:'red'}}>{error}</div>
                            <form ref={formdata} onSubmit={sendEmail}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" name='fname' className="form-control" value={fname} onChange={(e) => setFname(e.target.value)} id="fname" placeholder="Your First Name"/>
                                            <label>First Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" name='lname' className="form-control" value={lname} onChange={(e) => setLname(e.target.value)} id="lname" placeholder="Your Last Name"/>
                                            <label >Last Name</label>
                                        </div>
                                    </div>
									
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" name="mobileno" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} id="MobileNo" placeholder="Your Mobile"/>
                                            <label>Mobile No</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="date" className="form-control" name="datetime" value={dateTime} onChange={(e) => setDateTime(e.target.value)} id="journeydate" placeholder="Your Journey date"/>
                                            <label>Journey Date</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" name="pickup" value={pickup} onChange={(e) => setPickup(e.target.value)} id="pickupLocation" placeholder="Your Pickup Location"/>
                                            <label>Pick Up</label>
                                        </div>
                                    </div>									
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" name="drop" value={drop} onChange={(e) => setDrop(e.target.value)} id="dropLocation" placeholder="Your Drop Location"/>
                                            <label>Drop</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">
											Book Now <i className="fas fa-paper-plane"></i>
										</button>
										
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="container-xxl py-5" id="services">
            <div className="container py-5 px-lg-5">
                <div className="row g-4">
					<div className="wow fadeInUp" data-wow-delay="0.1s">
						<p className="section-title text-secondary justify-content-center"><span></span>Our Services<span></span></p>
					</div>
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <i className="fa fa-3x fa fa-car text-primary mb-4"></i>
                            <h5 className="mb-3">Local Car Rentals</h5>
                            <p className="m-0 text-data">We provide local car rentals are services that allow you to rent a vehicle, typically for short-term use, from a location near you. These services are convenient for various purposes, 
							such as when your personal vehicle is in for repairs, when you're traveling, or when you need a specific type of vehicle for a particular task.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <i className="fa fa-3x fa fa-taxi text-primary mb-4"></i>
                            <h5 className="mb-3">Outstation Cab</h5>
                            <p className="m-0 text-data">Typically refers to a taxi or cab service that provides transportation for journeys or trips that extend beyond the city or town where the taxi service is based. 
							These services are commonly used for long-distance travel, such as going to another city, 
							a tourist destination, or for business purposes.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <i className="fa fa-3x fa fa-road text-primary mb-4"></i>
                            <h5 className="mb-3">One way cabs</h5>
                            <p className="m-0 text-data">Refer to a type of taxi or cab service where a customer hires a vehicle to travel from one location to another without the need to return the vehicle to its original location. 
							In essence, it's a single journey without the expectation of a round trip.</p>
                        </div>
                    </div>
					<div className="col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <i className="fa fa-3x fa fa-plane text-primary mb-4"></i>
                            <h5 className="mb-3">Airport Cab</h5>
                            <p className="m-0 text-data">Rrefer to a type of taxi or cab service where a customer hires a vehicle to travel from one location to another without the need to return the vehicle to its original location. In essence, it's a single journey without the expectation of a round trip</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-xxl bgcolor  py-5 wow fadeInUp" data-wow-delay="0.1s">
			<div className="fact">
				<div className="container py-5 px-lg-5">
					<div className="row g-4">
						<div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
							<i className="fa fa-users fa-3x text-secondary mb-3"></i>
							<h1 className="text-white mb-2" data-toggle="counter-up">1000</h1>
							<p className="text-white mb-0">Drivers Partners</p>
						</div>
						<div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
							<i className="fa fa-map fa-3x text-secondary mb-3"></i>
							<h1 className="text-white mb-2" data-toggle="counter-up">10</h1>
							<p className="text-white mb-0">+Lack KM Journey Completed</p>
						</div>
						<div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
							<i className="fa fa-users fa-3x text-secondary mb-3"></i>
							<h1 className="text-white mb-2" data-toggle="counter-up">10000</h1>
							<p className="text-white mb-0">+ Satisfied Customers</p>
						</div>
						<div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
							<i className="fa fa-building fa-3x text-secondary mb-3"></i>
							<h1 className="text-white mb-2" data-toggle="counter-up">1000</h1>
							<p className="text-white mb-0">+ Cities </p>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div className="container-xxl py-5" id="cabs">
            <div className="container py-5 px-lg-5">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                    <p className="section-title text-secondary justify-content-center"><span></span>Our Cabs<span></span></p>                    
                </div>
                
                <div className="row g-4 portfolio-container">
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.1s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <img className="img-fluid w-100 card-img" src="images/swiftCab.png" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/swiftCab.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-4 cabs-services">4 <i className="fa fa-users" aria-hidden="true"></i></div>								
                                <div className="col-md-4 cabs-services">2 <i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                <div className="col-md-4 cabs-services">Ac</div>
                            </div>
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Compact</p>
                                <p className="lh-base mb-0 text-data">Compact cars are quite popular in India due to their affordability, fuel efficiency, and compact size Ex. Swift, Hyundai i10, Tiago,Kia,WagonR</p>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-6 cabs-services">12/Km</div>
                                <div className="col-md-6"><button type="button" className="btn btn-primary">Book Now</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow second wow fadeInUp" data-wow-delay="0.3s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <img className="img-fluid w-100 card-img" src="images/swiftDzireCab.png" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/swiftDzireCab.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
							<div className="bg-light p-2 row">
                                <div className="col-md-4 cabs-services">7 <i className="fa fa-users" aria-hidden="true"></i></div>								
                                <div className="col-md-4 cabs-services">3 <i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                <div className="col-md-4 cabs-services">Ac</div>
                            </div>
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Sedan</p>
                                <p className="lh-base mb-0 text-data">Sedans are known for their comfortable and practical design, making them a popular choice for individuals and families. Ex. Dzire,Honda City,Hyundai Verna,Skoda Rapid,Vento</p>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-6 cabs-services">14/Km</div>
                                <div className="col-md-6"><button type="button" className="btn btn-primary">Book Now</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.5s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <img className="img-fluid w-100 card-img" src="images/ertigaCab.png" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/ertigaCab.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-4 cabs-services">4 <i className="fa fa-users" aria-hidden="true"></i></div>								
                                <div className="col-md-4 cabs-services">3 <i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                <div className="col-md-4 cabs-services">Ac</div>
                            </div>
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Ertiga</p>
                                <p className="lh-base mb-0 text-data">The Maruti Suzuki Ertiga is known for its features that cater to the needs of families and individuals looking for a comfortable and practical MPV. Ex. Ertiga</p>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-6 cabs-services">16/Km</div>
                                <div className="col-md-6"><button type="button" className="btn btn-primary">Book Now</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow second wow fadeInUp" data-wow-delay="0.1s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <img className="img-fluid w-100 card-img" src="images/innovaCab.png" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/innovaCab.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-4 cabs-services">7 <i className="fa fa-users" aria-hidden="true"></i></div>								
                                <div className="col-md-4 cabs-services">3 <i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                <div className="col-md-4 cabs-services">Ac</div>
                            </div>
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Innova</p>
                                <p className="lh-base mb-0 text-data">The Innova has gone through several iterations and updates over the years, with variations tailored to different markets. Ex. Innova</p>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-6 cabs-services">18/Km</div>
                                <div className="col-md-6"><button type="button" className="btn btn-primary">Book Now</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.3s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden">
                                <img className="img-fluid w-100 card-img" src="images/crystaCab.png" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/crystaCab.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    
                                </div>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-4 col-sm-4 col-xs-4 cabs-services">7 <i className="fa fa-users" aria-hidden="true"></i></div>								
                                <div className="col-md-4 col-sm-4 col-xs-4 cabs-services">3 <i className="fa fa-suitcase" aria-hidden="true"></i></div>
                                <div className="col-md-4 col-sm-4 col-xs-4 cabs-services">Ac</div>
                            </div>
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Innova Crysta</p>
                                <p className="lh-base mb-0 text-data">The Innova Crysta is known for its versatility, comfort, and reliability, making it a popular choice for family transportation and commercial use in some regions. Ex. Innova Crysta</p>
                            </div>
                            <div className="bg-light p-2 row">
                                <div className="col-md-6 cabs-services">20/Km</div>
                                <div className="col-md-6"><button type="button" className="btn btn-primary">Book Now</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                    <p className="section-title text-secondary justify-content-center"><span></span>Our Service<span></span></p>                    
                </div>
                
                <div className="row g-4 portfolio-container">
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.1s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden card-image">
                                <img className="img-fluid w-100 card-img" src="images/pune.jpg" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/pune.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>                            
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Mumbai To Pune</p>
                                <p className="lh-base mb-0 text-data">
								Pune, a city in the Indian state of Maharashtra, is known for its rich history, vibrant culture, and a mix of modern and traditional attractions. Here are some popular places to visit in Pune:

									<p><b>Shaniwar Wada:</b> This historic fortification and palace is an iconic landmark in Pune, known for its intricate architecture and historical significance.</p>

									<p><b>Dagdusheth Halwai Ganpati Temple:</b> This famous Ganesh temple is a center of devotion and a popular tourist destination.</p>
									<p><b>Sinhagad Fort:</b> Located on a hill, this fort offers stunning views of the surrounding countryside. It's a popular spot for trekking and picnics.</p>
									<p><b>Saras Baug:</b> A well-maintained garden with a Ganesh temple and a beautiful lake.
									Darshan Museum: This museum provides insights into the life and teachings of Sadhu Vaswani, a spiritual leader.</p>
								</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow second wow fadeInUp" data-wow-delay="0.3s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden card-image">
                                <img className="img-fluid w-100 card-img" src="images/kolhapur.png" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/kolhapur.png" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
							
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Mumbai To Kolhapur</p>
                                <p className="lh-base mb-0 text-data">
								Kolhapur is a city located in the southwestern part of the Indian state of Maharashtra. It is the district headquarters of the Kolhapur district and is known for its historical, cultural, and economic significance. Here are some key aspects of Kolhapur:
									<p><b>Mahalaxmi Temple:</b> The Mahalaxmi Temple is one of the most famous religious sites in Kolhapur. It is dedicated to the goddess Mahalaxmi and is known for its architectural beauty and spiritual significance.</p>

									<p><b>Shri Chhatrapati Shahu Museum:</b> This historical palace, built in the 19th century, serves as a museum showcasing artifacts and memorabilia from the Chhatrapati rulers of Kolhapur. The palace architecture and the surrounding gardens are also worth exploring.</p>
									<p><b>Rankala Lake:</b> Rankala Lake is a picturesque and popular spot in Kolhapur. It's an ideal place for leisurely walks, boating, and enjoying the scenic beauty. The lake has a serene ambiance and is particularly lovely during sunset.</p>
									<p><b>Panhala Fort:</b> Situated about 20 kilometers from Kolhapur, Panhala Fort is one of the largest and most important forts in Maharashtra. It offers a glimpse into the historical significance of the region and provides panoramic views of the landscape.</p>
								</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.5s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden card-image">
                                <img className="img-fluid w-100 card-img" src="images/shirdi.jpg" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/shirdi.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
                            
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Mumbai To Shirdi</p>
                                <p className="lh-base mb-0 text-data">
								Shirdi is a small town located in the Ahmednagar district of the Indian state of Maharashtra. It is famous for being the home of the revered Indian spiritual leader, Sai Baba, who lived there for much of his life. Sai Baba is a prominent figure in Indian spirituality and is venerated by people of various religious backgrounds.
									<p><b>Sai Baba Temple:</b> This is the main temple in Shirdi, dedicated to Sai Baba. It is one of the most visited and revered places in the town.
									</p>
									<p><b>Chavadi:</b> Chavadi is a building where Sai Baba used to spend alternate nights. It has become a place of religious importance.</p>
									<p><b>Samadhi Mandir:</b> This is the place where the mortal remains of Sai Baba are interred. Devotees come here to pay their respects.</p>
									
								</p>
                            </div>                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow second wow fadeInUp" data-wow-delay="0.1s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden card-image">
                                <img className="img-fluid w-100 card-img" src="images/bhimashankar.jpg" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/bhimashankar.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                </div>
                            </div>
                            
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Mumbai To Bhimashankar</p>
                                <p className="lh-base mb-0 text-data">The Bhimashankar Temple is one of the 12 Jyotirlinga temples in India and is dedicated to Lord Shiva. It is located in the Sahyadri range of the Western Ghats, within the Bhimashankar Wildlife Sanctuary in the Pune district of Maharashtra. The temple is renowned for its religious significance, architectural beauty, and its picturesque natural surroundings.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.3s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden card-image">
                                <img className="img-fluid w-100 card-img" src="images/Mahabaleshwar.jpg" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/Mahabaleshwar.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    
                                </div>
                            </div>                            
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Mumbai To Mahableshwar</p>
								<p className="lh-base mb-0 text-data">
									Mahabaleshwar is a popular hill station and tourist destination located in the Indian state of Maharashtra. It is situated in the Western Ghats range, at an elevation of about 1,372 meters (4,501 feet) above sea level. Mahabaleshwar is known for its pleasant climate, lush greenery, and breathtaking views
									
									<p><b>Mapro Garden:</b> Mahabaleshwar is famous for strawberries, and the Mapro Garden is a popular attraction where you can learn about strawberry cultivation and taste a variety of strawberry-based products.</p>
									<p><b>Pratapgarh Fort:</b> This historic fort is located near Mahabaleshwar and is associated with the Maratha ruler, Chhatrapati Shivaji. The fort provides a glimpse into the region's historical significance.</p>
								</p>
                            </div>                           
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 portfolio-item box-shadow first wow fadeInUp" data-wow-delay="0.3s">
                        <div className="rounded overflow-hidden">
                            <div className="position-relative overflow-hidden card-image">
                                <img className="img-fluid w-100 card-img" src="images/Ashtavinayak.jpg" alt=""/>
                                <div className="portfolio-overlay">
                                    <a className="btn btn-square btn-outline-light mx-1" href="images/Ashtavinayak.jpg" data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    
                                </div>
                            </div>                            
							<div className="bg-light p-4">
                                <p className="text-primary fw-medium mb-2">Mumbai To Ashthvinayak Darshan</p>
								<p className="lh-base mb-0 text-data">
									The Ashtavinayak Darshan is a pilgrimage tour that takes devotees to visit eight ancient temples in the Indian state of Maharashtra, which are dedicated to Lord Ganesha, one of the most widely worshipped deities in Hinduism. These temples are collectively known as the "Ashtavinayak" temples, and the pilgrimage is considered highly sacred by followers of Lord Ganesha.
								</p>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-xxl py-5 wow fadeInUp" id="feedback" data-wow-delay="0.1s">
            <div className="container py-5 px-lg-5">
                <p className="section-title text-secondary justify-content-center"><span></span>Feedback<span></span></p>
                
                <div className="">
                    <div className="testimonial-item bg-light rounded my-4">
                        <p className="fs-5 text-data"><i className="fa fa-quote-left fa-2x text-primary mt-n4 me-3"></i>
							I recently used EkViraai cab service for a trip and I was thoroughly impressed with their service. From start to finish, everything was seamless and convenient.

							The booking process was quick and easy, and the staff was incredibly helpful in guiding me through the options available. 
							The vehicle I rented was clean, well-maintained, and comfortable. It felt like a safe and reliable choice for my journey.
						</p>
                        <div className="d-flex align-items-center">
                            <img className="img-fluid flex-shrink-0 rounded-circle" src="images/user-icon.png" style={{width: '65px', height: '65px'}}/>
                            <div className="ps-4">
                                <h5 className="mb-1">Rajesh Sharma</h5>
                                <span>Happy Customer</span>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-item bg-light rounded my-4">
                        <p className="fs-5 text-data"><i className="fa fa-quote-left fa-2x text-primary mt-n4 me-3"></i>
						I recently used Ekvira Aai cab for a trip, and I must say it was a fantastic experience. 
						From start to finish, the service was top-notch. Here's a breakdown of my experience
						</p>
                        <div className="d-flex align-items-center">
                            <img className="img-fluid flex-shrink-0 rounded-circle" src="images/user-icon.png" style={{width: '65px', height: '65px'}}/>
                            <div className="ps-4">
                                <h5 className="mb-1">Aman kanna</h5>
                                <span>Happy Customer</span>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-item bg-light rounded my-4">
                        <p className="fs-5 text-data"><i className="fa fa-quote-left fa-2x text-primary mt-n4 me-3"></i>
						The vehicle I rented was clean and well-maintained. It was comfortable, and I had no issues during the journey. However, a minor aesthetic issue on the exterior was the only reason I didn't give it a perfect score.
						</p>
                        <div className="d-flex align-items-center">
                            <img className="img-fluid flex-shrink-0 rounded-circle" src="images/user-icon.png" style={{width: '65px', height: '65px'}}/>
                            <div className="ps-4">
                                <h5 className="mb-1">Amit Mahajan</h5>
                                <span>Happy Customer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div className="bgcolor">
        <div className="container-fluid  text-light footer wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5 px-lg-5">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-3">
                        <p className="section-title text-white h5 mb-4">Address<span></span></p>
                        <p><i className="fa fa-map-marker-alt me-3"></i>ss 2 Room no 553 2nd floor Sector 5 Near SBI Bank Koparkhairne Navi Mumbai</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+91 9309226943</p>
                        <p><i className="fa fa-envelope me-3"></i>ekviraaaicab@gmail.com</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <p className="section-title text-white h5 mb-4">Regular Trips<span></span></p>
                        <ul className="list-inline campany-list">
						  <li><a href="#">Mumbai To Goa</a></li>
						  <li><a href="#">Mumbai To Bhandardara</a></li>
						  <li><a href="#">Mumbai To Shirdi</a></li>
						  <li><a href="#">Mumbai To Bhimashankar</a></li>
						  <li><a href="#">Pune To Malvan</a></li>
						</ul>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <p className="section-title text-white h5 mb-4">Regular Trips<span></span></p>
                        <div className="row g-2">
                           <ul className="list-inline campany-list">
							  <li><a href="#">Mumbai To Pune</a></li>
							  <li><a href="#">Mumbai To Nashik</a></li>
							  <li><a href="#">Mumbai To Aurangabad</a></li>
							  <li><a href="#">Mumbai To Satara</a></li>
							  <li><a href="#">Mumbai To Solapur</a></li>
							  <li><a href="#">Mumbai To Kolhapur</a></li>
							  <li><a href="#">Mumbai To Mahableswar</a></li>
							  <li><a href="#">Mumbai To Alibug</a></li>
							</ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <p className="section-title text-white h5 mb-4">Regular Trips<span></span></p>
                        
                        <div className="position-relative w-100 mt-3">
                            <ul className="list-inline campany-list">
							  <li><a href="#">Mumbai To Pune</a></li>
							  <li><a href="#">Mumbai To Nashik</a></li>
							  <li><a href="#">Mumbai To Aurangabad</a></li>
							  <li><a href="#">Mumbai To Satara</a></li>
							  <li><a href="#">Mumbai To Solapur</a></li>
							  <li><a href="#">Mumbai To Kolhapur</a></li>
							  <li><a href="#">Mumbai To Mahableswar</a></li>
							  <li><a href="#">Mumbai To Alibug</a></li>
							</ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container px-lg-5">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="border-bottom" href="#">Ekvira Aai Cab</a>, All Right Reserved. 
							Designed By <a className="border-bottom" href="https://www.ellipsesofttech.com">Ellise Software Technology</a>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
							<a href="#home" >Home</a>
							<a href="#services" >Service</a>
							<a href="#feedback" >Feedback</a>
							<a href="#faq" >Faq</a>
							
							<a href="#contactus">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</div>
        
        <a href="#" className="btn btn-lg btn-secondary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </div>

      </header>
    </div>
  );
}

export default App;
